import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import i18n from "./lang";
import '@/styles/style.scss';

import {createPinia, Pinia} from "pinia";
import {improveLinks} from "@/services/improveLinks";
import {createHead, HeadClient} from "@vueuse/head";
import * as Sentry from "@sentry/vue";

const pinia:Pinia = createPinia();
const head:HeadClient = createHead()

const app = createApp(App);
Sentry.init({
    app,
    dsn: "https://b8e4bebad80c9287daf1dc296149ad7f@o4507242603282432.ingest.de.sentry.io/4507242623205456",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/festa\.navtech\.cloud\/api\/v1/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});



    app.use(router).use(i18n);
    app.use(pinia);
    app.use(head);
    app.directive('improve-links', improveLinks);
    app.mount('#app');
